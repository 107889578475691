<template>
  <div class="reset-password-request">
    <Header />
    <div class="container">
      <h4 class="text-center title">Reset Password Request</h4>
      <v-form @submit="onSubmit">
        <div class="my-2" v-if="errors.length">
          <strong>Please correct the following error(s):</strong>
          <ul>
            <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
          </ul>
        </div>
        <v-text-field id="input-1" v-model="form.email" type="email" required placeholder="Enter email"
          label="Email address:"></v-text-field>

        <v-btn type="submit" color="primary">Send Request</v-btn>
      </v-form>
      <div class="mt-5 text-center">
        Don't have an account yet?
        <a href="https://salesgrowth.com/gap-selling-course-pricing/">Register</a>
        <!-- <a href="/sign-up">Register</a> -->
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  max-width: 500px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
  padding: 25px;
  margin: 30px auto;
  background-color: white;
}

.title a {
  color: #00abc2;
}
</style>

<script>
import REQUEST_PASSWORD_RESET from "@/graphql/ResetPasswordRequest.gql";
import Header from "@/components/Header.vue";
import router from "@/router";

export default {
  components: { Header },
  data() {
    return {
      errors: [],
      form: {
        email: "",
      },
      show: true,
    };
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault();

      this.errors = [];
      try {
        const requestResult = await this.$apollo.mutate({
          mutation: REQUEST_PASSWORD_RESET,
          variables: {
            email: this.form.email,
          },
        });
        if (requestResult) {
          alert("please check your email!");
          router.push({ name: "signin" });
        } else {
          alert("something is not right. please try another email address.");
        }
      } catch (error) {
        this.errors.push(error.toString());
        alert("something is not right. please try another email address.");
      }
    },
  },
};
</script>
